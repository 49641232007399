import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Divider, Col, Row, Input, Form, notification } from 'antd';

import FormHeader from '@controls/form-header/form-header';
import Trackpoints from './trackpoints';

import { exception } from '@extensions/notification';
import { getDate } from '@extensions/utils';

import { userLoaded } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { ITruck } from '@entities/truck';
import { TruckStatus, enumLabel } from '@enums/truck-status';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Truck = () => {
    const { id } = useParams();

    const d = useDispatch();

    const [api, notificationContextHolder] = notification.useNotification();

    const [entity, setEntity] = useState<ITruck>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        initData();
    }, []);

    const initData = async () => {
        setLoading(true);

        serverFetch(`trucks/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения машины', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader title='Информация о машине' />

            {!loading && (
                <>
                    <Row>
                        <Col span={12}>
                            <Form colon={false} labelCol={{ span: 6 }} wrapperCol={{ span: 10 }}>
                                <Form.Item initialValue={entity?.number} label='Номер машины' name='number' wrapperCol={{ span: 5 }}>
                                    <Input disabled={true} />
                                </Form.Item>
                                <Form.Item label='Статус' wrapperCol={{ span: 5 }}>
                                    <Input disabled={true} defaultValue={enumLabel(entity?.status)} />
                                </Form.Item>

                                {entity?.status !== TruckStatus.New && (
                                    <Form.Item
                                        wrapperCol={{ span: 5 }}
                                        label='Дата отправки'
                                        name='departureOn'
                                        initialValue={getDate(entity?.departureOn)}
                                    >
                                        <Input disabled={true} />
                                    </Form.Item>
                                )}
                            </Form>
                        </Col>
                    </Row>
                    {entity?.status !== TruckStatus.New && (
                        <>
                            <Row style={{ marginBottom: 15, marginTop: 15 }}>
                                <Col span={16}>
                                    <Divider orientation='left'>Трекинг</Divider>
                                </Col>
                                <Col offset={1} span={15}>
                                    <Trackpoints truckId={id} headerId={entity?.waypointHeaderId} viewOnly={true} />
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            )}

            {notificationContextHolder}
        </>
    );
};

export default Truck;
