import React, { useState, useEffect, useRef } from 'react';

import { useResizeObserver } from 'usehooks-ts';
import { Dayjs } from 'dayjs';
import { saveAs } from 'file-saver';

import { Table, Input, DatePicker, Form, Switch, Flex, Button, Tooltip, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, FilterFilled, DownloadOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import { exception } from '@extensions/notification';
import { delayAction, dataURItoBlob, getDateTimeLocal } from '@extensions/utils';

import { userLoaded, setFilter } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { IUserSession } from '@entities/user-session';
import { IDocument } from '@entities/document';
import { IDocumentFilter } from '@entities/document-filter';

import { DocumentReferenceType } from '@enums/document-reference-type';
import { UserType } from '@enums/user-type';

import { serverFetch } from '@src/core/server';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const filterContext: string = 'UserFiles';

const Files = () => {
    const { RangePicker } = DatePicker;

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const initFilter: IDocumentFilter = { referenceType: DocumentReferenceType.Consignee, isNewOnly: false, userId: userSession.userId };

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const filter = useAppSelector<IDocumentFilter>((s) => s.filters[filterContext]);

    const [documents, setDocuments] = useState<Array<IDocument>>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState<boolean>(true);

    useEffect(() => {
        if (!refreshRequired) return;

        let cleanup = false;

        setDocuments([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('documents', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения документов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setDocuments(result[0][0]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onDownload = (document: IDocument) => {
        if (!document) return;

        serverFetch(`documents/${document?.id}/download`, { method: 'GET' })
            .then((data) => {
                let fileBody = `data:${data.contentType};base64,${data.fileContents}`;
                saveAs(dataURItoBlob(fileBody), data.fileDownloadName);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка загрузки документа', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <Input
                            style={{ width: 100, display: userSession.type == UserType.Supplier ? '' : 'none' }}
                            key='consigneeCode'
                            placeholder='ID'
                            value={filter?.consigneeCode}
                            onChange={(data) => {
                                d(setFilter({ ...filter, consigneeCode: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            key='searchText'
                            placeholder='Название...'
                            value={filter?.searchText}
                            style={{ width: 250 }}
                            onChange={(data) => {
                                d(setFilter({ ...filter, searchText: data.target.value }, filterContext));
                            }}
                        />,
                        <RangePicker
                            style={{ width: 260 }}
                            allowEmpty={[true, true]}
                            key='created'
                            format='DD.MM.YYYY'
                            placeholder={['Отправлен с', 'до']}
                            cellRender={(current) => {
                                return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                            }}
                            value={[filter?.dateFrom ? dayjs(filter?.dateFrom) : null, filter?.dateTo ? dayjs(filter?.dateTo) : null]}
                            onChange={(value) => {
                                if (!value) {
                                    d(
                                        setFilter(
                                            {
                                                ...filter,
                                                dateFrom: undefined,
                                                dateTo: undefined,
                                            },
                                            filterContext
                                        )
                                    );

                                    return;
                                }

                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            dateFrom: value[0]
                                                ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                            dateTo: value[1]
                                                ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                        },
                                        filterContext
                                    )
                                );
                            }}
                        />,
                        <Form.Item key='downloaded' label='Новые' style={{ margin: 0 }}>
                            <Switch
                                checked={filter?.isNewOnly}
                                onChange={(value) => {
                                    d(setFilter({ ...filter, isNewOnly: value }, filterContext));
                                }}
                            />
                        </Form.Item>,
                    ]}
                    onClear={() => d(setFilter({ ...initFilter }, filterContext))}
                />
            </div>
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IDocument> = [
            {
                align: 'center',
                width: 35,
                render: (_, record) => {
                    return (
                        <Flex>
                            <Tooltip title='Скачать'>
                                <Button size='small' type='link' icon={<DownloadOutlined />} onClick={() => onDownload(record)} />
                            </Tooltip>
                        </Flex>
                    );
                },
            },
            {
                title: 'ID',
                dataIndex: 'referenceName',
                width: 100,
                hidden: userSession.type != UserType.Supplier,
            },
            {
                title: 'Название',
                render: (_, record) => {
                    return record.name;
                },
            },
            {
                title: 'Дата',
                width: 130,
                render: (_, record) => {
                    return getDateTimeLocal(record.createdOn);
                },
            },
        ];

        return (
            <Table
                virtual={true}
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={documents}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                onRow={(record) => {
                    return {
                        style: {
                            fontWeight: record.isDownloaded ? '' : 600,
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: height ?? 0 }}
            />
        );
    };

    return (
        <>
            <div ref={containerRef} style={{ height: `calc(100vh - 190px - ${filterHeight}px)` }}>
                {renderToolbar()}
                {showFilter && renderFilter()}
                {renderTable()}
            </div>

            {contextHolder}
        </>
    );
};

export default Files;
