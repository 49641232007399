import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Rate, Card, Steps, Row, Col, Tooltip, Button, Modal, Input, notification, Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';

import { CarOutlined, EyeFilled, PlusOutlined, QuestionCircleOutlined, CheckCircleOutlined, PaperClipOutlined } from '@ant-design/icons';

import FormHeader from '@controls/form-header/form-header';
import Bill from '@src/core/controls/bill/bill';

import { exception, info } from '@extensions/notification';
import { getBase64RcFile, getDateTimeLocal, getDate } from '@extensions/utils';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IWaypoint } from '@entities/waypoint';
import { ITrackpoint } from '@entities/trackpoint';
import { IFeedback } from '@entities/feedback';
import { IUserSession } from '@entities/user-session';
import { IPoint } from '@entities/point';
import { ITruckOrder } from '@entities/truck-order';
import { ITransitWarehouse } from '@entities/transit-warehouse';
import { IImportDetails } from '@entities/import-details';
import { IDeliveryDetails } from '@entities/delivery-details';
import { IBill } from '@entities/bill';
import { IBillFilter } from '@entities/bill-filter';

import { TruckStatus } from '@enums/truck-status';
import { WaypointType } from '@enums/waypoint-type';
import { DeliveryOption, enumLabel as deliveryOptionLabel } from '@enums/delivery-option';
import { UserType } from '@enums/user-type';
import { BillStatus } from '@enums/bill-status';

import { TruckIcon, AirplaneIcon, PickupIcon, BillIcon } from '@icons/index';

import './order.css';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Order = () => {
    var Buffer = require('buffer/').Buffer;

    const { id } = useParams();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [form] = Form.useForm();

    const { TextArea } = Input;

    const [modal, modalContextHolder] = Modal.useModal();
    const [api, notificationContextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const [importDetails, setImportDetails] = useState<IImportDetails>();
    const [deliveryDetails, setDeliveryDetails] = useState<IDeliveryDetails>();
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [openFeedback, setOpenFeedback] = useState<boolean>(false);
    const [fileList, setFileList] = useState<Array<UploadFile>>([]);
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>('');
    const [previewTitle, setPreviewTitle] = useState<string>('');
    const [feedback, setFeedback] = useState<IFeedback>({ rate: undefined, imageIds: [] });

    const [bills, setBills] = useState<Array<IBill>>([]);
    const [showBillsRequired, setShowBillsRequired] = useState<boolean>(false);
    const [openBill, setOpenBill] = useState<boolean>(false);

    useEffect(() => {
        if (!refreshRequired) return;

        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch(userSession.isDemo ? `demo/${id}/import` : `orders/${id}/import`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения импорта', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(userSession.isDemo ? `demo/${id}/delivery` : `orders/${id}/delivery`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения доставки', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setImportDetails(result[0][0]);
                setDeliveryDetails(result[0][1]);

                setRefreshRequired(false);
                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!showBillsRequired) return;

        setShowBillsRequired(false);

        if (bills.length <= 0) {
            info(api, 'Выставленных счетов не найдено');
            return;
        }

        setOpenBill(true);
    }, [showBillsRequired, bills]);

    const onConfirm = (journalId: string | undefined) => {
        if (!journalId) return;

        serverFetch(`orders/${id}/delivery/${journalId}/confirm`, { method: 'POST' })
            .then((data) => {
                setFeedback({ ...feedback, packingListId: data });
                setOpenFeedback(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка подтверждения получения груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const onLoadBill = () => {
        let tariffFilter: IBillFilter = {
            userId: userSession.userId,
            boxGroupId: id,
            statuses: [BillStatus.Completed, BillStatus.ToVerify],
        };

        serverFetch('bills', { method: 'GET', queryParams: tariffFilter })
            .then((data) => {
                setShowBillsRequired(true);
                setBills(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения счетов', ex, () => d(userLoaded(undefined)));
            });
    };

    const onAddFeedback = () => {
        feedback.imageIds = fileList.map((f) => f.uid);

        serverFetch(`feedbacks`, { method: 'POST', bodyData: feedback })
            .then(() => {
                form.resetFields();
                setOpenFeedback(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения отзыва', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDownload = async (file: any) => {
        if (file) {
            if (!file.url && !file.preview) {
                var fileObj = file.originFileObj;

                if (typeof fileObj !== 'object') {
                    const buffer = new Buffer.from(file.originFileObj, 'base64');
                    fileObj = new Blob([buffer], { type: 'application/pdf' });
                }
            }

            var fileURL = URL.createObjectURL(fileObj);
            window.open(fileURL);
        }
    };

    const onUploadImage = (file: Blob): Promise<string> => {
        const formData = new FormData();
        formData.append('file', file);

        return serverFetch(`feedbacks/image`, { method: 'POST', body: formData })
            .then((data) => {
                return data;
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка загрузки изображения', ex, () => d(userLoaded(undefined)));
                return undefined;
            });
    };

    const handleChange: UploadProps['onChange'] = async ({ file, fileList }) => {
        if (file) {
            var result = await onUploadImage(file.originFileObj as Blob);

            if (result) {
                file.status = 'done';
                file.uid = result;
            }
        }

        setFileList(fileList);
    };

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64RcFile(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const getRouteSheet = (waypoints: Array<IWaypoint>, trackpoints: Array<ITrackpoint>, truckNumber: string | undefined) => {
        if (!waypoints || waypoints.length <= 0) return;

        let trackpointsBuff = [...trackpoints];
        let points: Array<IPoint> = [];
        let index = -1;
        let currentWaypoint: IWaypoint | undefined = undefined;

        let extraTrackpoints = trackpointsBuff.filter((t) => t.isExtraPoint);

        let waypointsBuff: Array<IWaypoint> = [];

        waypoints.map((w) => {
            let extraPoints = extraTrackpoints.filter((t) => t.waypointId === w.id);
            if (extraPoints.length > 0) {
                extraPoints.map((p) => {
                    waypointsBuff.push({ ...w, type: WaypointType.Onway, extraTrackpointId: p.id });
                });
            }

            waypointsBuff.push(w);
        });

        waypointsBuff.map((w) => {
            let title: any = '';
            if (!w.type || w.type === WaypointType.Onway) {
                title = <CarOutlined />;
            } else if (w.type === WaypointType.Destination) {
                title = w.name;
            } else {
                title = w.name;
            }

            let point: IPoint = { id: w.id, title: title, type: w.type };

            let trackpoint = w.extraTrackpointId
                ? trackpoints.find((t) => t.id === w.extraTrackpointId)
                : trackpoints.find((t) => !t.isExtraPoint && t.waypointId === w.id);

            if (trackpoint) {
                point.description = trackpoint.comment;
                point.subTitle = getDateTimeLocal(trackpoint.date);
            }

            points.push(point);
        });

        let lastTrackPoint: any = trackpointsBuff.pop();

        if (lastTrackPoint) {
            currentWaypoint = lastTrackPoint.isExtraPoint
                ? waypointsBuff.find((w) => w.extraTrackpointId === lastTrackPoint?.id)
                : waypointsBuff.find((w) => !w.extraTrackpointId && w.id === lastTrackPoint?.waypointId);

            if (currentWaypoint) {
                index = waypointsBuff.indexOf(currentWaypoint);
            }
        }

        return {
            index: index,
            points: points,
            currentWaypoint: currentWaypoint,
        };
    };

    const renderDeliveryOptionIcon = (option: DeliveryOption) => {
        switch (option) {
            case DeliveryOption.Truck:
                return (
                    <Tooltip placement='top' title={deliveryOptionLabel(DeliveryOption.Truck)}>
                        <TruckIcon className='delivery-option icon-background' />
                    </Tooltip>
                );
            case DeliveryOption.Pickup:
                return (
                    <Tooltip placement='top' title={deliveryOptionLabel(DeliveryOption.Pickup)}>
                        <PickupIcon className='delivery-option icon-background' />
                    </Tooltip>
                );
            case DeliveryOption.Airplane:
                return (
                    <Tooltip placement='top' title={deliveryOptionLabel(DeliveryOption.Airplane)}>
                        <AirplaneIcon className='delivery-option icon-background' />
                    </Tooltip>
                );
            default:
                return '';
        }
    };

    const renderImportCard = () => {
        let data = getRouteSheet(importDetails?.waypoints || [], importDetails?.trackpoints || [], importDetails?.truck?.number);

        return (
            <Card bordered={true} className='order-card'>
                <Row>
                    <Col span={12}>
                        {renderDeliveryOptionIcon(DeliveryOption.Truck)}

                        <h1 className='order-form-header' style={{ marginTop: 0 }}>
                            Общая информация
                        </h1>

                        <Form labelAlign='left' colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            {importDetails?.order?.loadingOn && (
                                <Form.Item label='Отгрузка' className='order-form-item'>
                                    <span className='order-form-loading'>{getDate(importDetails?.order.loadingOn)}</span>
                                    {userSession.userSettings?.showBills && (
                                        <span style={{ marginLeft: 10 }}>
                                            <Tooltip title='Показать счет'>
                                                <Button icon={<BillIcon />} onClick={() => onLoadBill()} />
                                            </Tooltip>
                                        </span>
                                    )}
                                </Form.Item>
                            )}
                            <Form.Item className='order-form-item' label='Маркировка'>
                                <div className='order-form-value'>{importDetails?.order?.markingCode}</div>
                            </Form.Item>
                            <Form.Item label='Страна' className='order-form-item'>
                                <div className='order-form-value'>{importDetails?.order?.countryName}</div>
                            </Form.Item>
                            <Form.Item label='AWB' className='order-form-item'>
                                <div className='order-form-awb'>{importDetails?.order?.awbNumber}</div>
                            </Form.Item>
                            <Form.Item label='Количество' className='order-form-item'>
                                <div className='order-form-value'>{importDetails?.order?.boxQty}</div>
                            </Form.Item>

                            {userSession.type != UserType.SubClient && importDetails?.documents && (
                                <>
                                    <h3 className='order-form-header' style={{ marginTop: 20 }}>
                                        Документы
                                    </h3>
                                    {importDetails.documents.map((d) => {
                                        return (
                                            <Button
                                                style={{ whiteSpace: 'normal', height: 'auto' }}
                                                icon={<PaperClipOutlined />}
                                                type='link'
                                                onClick={() => onDownload(d)}
                                            >
                                                {d.name}
                                            </Button>
                                        );
                                    })}
                                </>
                            )}
                        </Form>

                        {importDetails?.truck?.status !== TruckStatus.New && (
                            <>
                                <h1 className='order-form-header'>Отгружено</h1>
                                <Form labelAlign='left' colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
                                    {importDetails?.truck?.number && (
                                        <Form.Item label='Номер машины' className='order-form-item'>
                                            <div className='order-form-value'>
                                                {importDetails?.truck?.number}
                                                {importDetails?.truck?.trackingUrl && (
                                                    <Tooltip placement='topRight' title='Показать на карте'>
                                                        <a
                                                            className='trackingUrl'
                                                            href={importDetails?.truck?.trackingUrl}
                                                            target='_blank'
                                                            rel='noreferrer'
                                                        >
                                                            <EyeFilled />
                                                        </a>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </Form.Item>
                                    )}
                                    <Form.Item label='Количество' className='order-form-item'>
                                        <div className='order-form-value'>{importDetails?.order?.boxQty}</div>
                                    </Form.Item>
                                </Form>
                            </>
                        )}

                        {data && data.currentWaypoint && data.currentWaypoint.type === WaypointType.Destination && (
                            <>
                                <h1 className='order-form-header'>Принято на склад</h1>
                                <Form labelAlign='left' colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
                                    <Form.Item label='Количество' className='order-form-item'>
                                        <span
                                            className='order-form-value'
                                            style={{
                                                fontWeight: 600,
                                                color:
                                                    importDetails?.order?.acceptedBoxQty === importDetails?.order?.actualBoxQty
                                                        ? '#228B22'
                                                        : 'red',
                                            }}
                                        >
                                            {importDetails?.order?.acceptedBoxQty}
                                        </span>
                                        <span> / </span>
                                        <span className='order-form-value'>{importDetails?.order?.actualBoxQty}</span>
                                    </Form.Item>
                                    <Form.Item label='Остаток' className='order-form-item'>
                                        <span className='order-form-value'>
                                            {(importDetails?.order?.acceptedBoxQty || 0) - (importDetails?.order?.shippedBoxQty || 0)}
                                        </span>
                                    </Form.Item>
                                </Form>
                            </>
                        )}
                    </Col>
                    <Col span={7} offset={3}>
                        {data && <Steps progressDot direction='vertical' current={data.index} items={data.points} />}
                    </Col>
                </Row>
            </Card>
        );
    };

    const renderDeliveryCard = () => {
        return (
            <>
                {deliveryDetails?.transitOrders &&
                    deliveryDetails?.transitOrders.length > 0 &&
                    renderTransit(deliveryDetails.transitOrders, deliveryDetails.transitWarehouse)}

                {deliveryDetails?.deliveryOrders &&
                    deliveryDetails?.deliveryOrders.length > 0 &&
                    renderDelivery(deliveryDetails.deliveryOrders)}
            </>
        );
    };
    const renderTransit = (transitOrders: Array<ITruckOrder>, warehouse: ITransitWarehouse) => {
        return (
            <Card key='transitWarehouse' bordered={true} className='order-card delivery' style={{ borderColor: 'var(--primary-color)' }}>
                <Row>
                    <Col span={24}>
                        <h1 className='order-form-header' style={{ marginTop: 0, marginBottom: 20 }}>
                            Транзит
                        </h1>

                        {renderTruck(transitOrders, true)}

                        <h1 className='transit-warehouse' style={{ marginTop: 0 }}>
                            Принято на склад "{warehouse.cityName}"
                        </h1>
                        <Form labelAlign='left' colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
                            <Form.Item label='Количество' className='order-form-item'>
                                <span
                                    className='order-form-value'
                                    style={{
                                        fontWeight: 600,
                                        color: warehouse?.acceptedBoxQty === warehouse?.boxQty ? '#228B22' : 'red',
                                    }}
                                >
                                    {warehouse?.acceptedBoxQty}
                                </span>
                                <span> / </span>
                                <span className='order-form-value'>{warehouse?.boxQty}</span>
                            </Form.Item>
                            <Form.Item label='Остаток' className='order-form-item'>
                                <span className='order-form-value'>
                                    {(warehouse?.acceptedBoxQty || 0) - (warehouse?.shippedBoxQty || 0)}
                                </span>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Card>
        );
    };

    const renderDelivery = (deliveryOrders: Array<ITruckOrder>) => {
        return (
            <Card key='delivery' bordered={true} className='order-card delivery'>
                <Row>
                    <Col span={24}>
                        <h1 className='order-form-header' style={{ marginTop: 0, marginBottom: 20 }}>
                            Доставка
                        </h1>

                        {renderTruck(deliveryOrders, false)}
                    </Col>
                </Row>
            </Card>
        );
    };

    const renderTruck = (truckOrders: Array<ITruckOrder>, isTransit: boolean) => {
        return truckOrders.map((order) => {
            let data = getRouteSheet(order?.waypoints || [], order?.trackpoints || [], order?.truck?.number);

            return (
                <Card
                    key={order.truck?.id}
                    bordered={true}
                    className='order-card'
                    styles={{
                        body: {
                            paddingBottom: 0,
                        },
                    }}
                    style={{ marginBottom: 20 }}
                >
                    <Row>
                        <Col span={12}>
                            {renderDeliveryOptionIcon(order.deliveryOption)}

                            <Form labelAlign='left' colon={false} labelCol={{ span: 10 }} wrapperCol={{ span: 12 }}>
                                <Form.Item label='Количество' className='order-form-item'>
                                    <div className='order-form-value'>{order?.boxQty}</div>
                                </Form.Item>
                                {order.deliveryOption === DeliveryOption.Truck && (
                                    <Form.Item label='Телефон водителя' className='order-form-item'>
                                        <div className='order-form-value'>{order?.truck?.driverPhone}</div>
                                    </Form.Item>
                                )}
                                {order?.truck?.number && (
                                    <Form.Item label='Номер машины' className='order-form-item'>
                                        <div className='order-form-value'>
                                            {order?.truck?.number}
                                            {order?.truck?.trackingUrl && (
                                                <Tooltip placement='topRight' title='Показать на карте'>
                                                    <a
                                                        className='trackingUrl'
                                                        href={order?.truck?.trackingUrl}
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        <EyeFilled />
                                                    </a>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </Form.Item>
                                )}
                            </Form>
                        </Col>
                        <Col span={7} offset={4}>
                            {data && <Steps progressDot direction='vertical' current={data.index} items={data.points} />}
                        </Col>
                        {order.isReceived && (
                            <Col>
                                <Tooltip title='Груз получен' color={'#389e0d'}>
                                    <CheckCircleOutlined className='received' />
                                </Tooltip>
                            </Col>
                        )}
                    </Row>
                    {!userSession.isDemo && userSession.type != UserType.SubClient && !isTransit && !order.isReceived && (
                        <Row>
                            <Button
                                type='primary'
                                style={{ fontWeight: 600, marginBottom: 20, marginTop: 10 }}
                                onClick={() => {
                                    modal.confirm({
                                        title: `Подтвердить получение груза?`,
                                        icon: <QuestionCircleOutlined />,
                                        okType: 'primary',
                                        okText: 'ОК',
                                        cancelText: 'Отмена',
                                        onOk: () => {
                                            onConfirm(order.shipmentJournalId);
                                        },
                                    });
                                }}
                            >
                                Подтвердить получение
                            </Button>
                        </Row>
                    )}
                </Card>
            );
        });
    };

    return (
        <>
            <FormHeader title={`Детали партии`} />
            <Row style={{ marginTop: 10 }}>
                <Col span={12}>{renderImportCard()}</Col>
                <Col span={11}>{renderDeliveryCard()}</Col>
            </Row>

            <Modal
                width={600}
                title='Оцените доставку'
                open={openFeedback}
                closable={false}
                onOk={() => {
                    form.submit();
                }}
                okText='Отправить'
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout='vertical'
                    style={{ maxWidth: 600 }}
                    form={form}
                    onFinish={() => onAddFeedback()}
                >
                    <Form.Item
                        label='Оценка'
                        name='rate'
                        initialValue={feedback?.rate}
                        rules={[{ required: true, message: 'Оцените доставку' }]}
                    >
                        <Rate
                            allowClear={false}
                            onChange={(e) => {
                                setFeedback({ ...feedback, rate: e });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        label='Отзыв'
                        name='description'
                        rules={[{ required: feedback.rate ? feedback.rate < 5 : false, message: 'Опишите проблему' }]}
                        style={{ marginTop: 20 }}
                    >
                        <TextArea
                            rows={6}
                            onChange={(data) => {
                                setFeedback({ ...feedback, description: data.target.value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item label='Фотографии' style={{ marginTop: 20 }}>
                        <Upload
                            onPreview={handlePreview}
                            onChange={handleChange}
                            listType='picture-card'
                            maxCount={5}
                            //  multiple
                            fileList={fileList}
                            accept='image/png, image/jpeg'
                        >
                            {fileList.length < 5 && (
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}></div>
                                </div>
                            )}
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
                <img alt='example' style={{ width: '100%' }} src={previewImage} />
            </Modal>

            {openBill && (
                <Bill
                    bills={bills}
                    onClose={() => {
                        setBills([]);
                        setOpenBill(false);
                    }}
                />
            )}

            {notificationContextHolder}
            {modalContextHolder}
        </>
    );
};

export default Order;
