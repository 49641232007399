import React, { useState, useEffect, useRef } from 'react';

import { Dayjs } from 'dayjs';

import { useResizeObserver } from 'usehooks-ts';

import { Modal, Table, Form, Switch, DatePicker, notification, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, FilterFilled, EyeFilled, QuestionCircleOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import { exception } from '@extensions/notification';

import { delayAction, getDateTimeLocal } from '@extensions/utils';

import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IPushNotification } from '@entities/push-notification';
import { IPushNotificationFilter } from '@entities/push-notification-filter';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const filterContext: string = 'Notifications';

const Notifications = () => {
    const initFilter: IPushNotificationFilter = { personalOnly: true, unreadOnly: true };

    const d = useAppDispatch();

    const { RangePicker } = DatePicker;

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const [modal, modalCntextHolder] = Modal.useModal();

    const [api, contextHolder] = notification.useNotification();

    const [openDialog, setOpenDialog] = useState(false);

    const [entities, setEntities] = useState<Array<IPushNotification>>([]);
    const [currentEntity, setCurrentEntity] = useState<IPushNotification>();
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);

    const filter = useAppSelector<IPushNotificationFilter>((s) => s.filters[filterContext]);

    const [showFilter, setShowFilter] = useState<boolean>(true);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        setEntities([]);

        setLoading(true);

        serverFetch('notifications', { method: 'GET', queryParams: filter })
            .then((data) => {
                setEntities(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения сообщений', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onSetRead = () => {
        serverFetch(`notifications/${currentEntity?.id}/read`, { method: 'POST' })
            .then(() => {
                setOpenDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка изменения статуса сообщения', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSetReadAll = () => {
        serverFetch(`notifications/readall`, { method: 'POST' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка изменения статуса сообщения', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => setRefreshRequired(true),
                    },
                    {
                        label: 'Прочитать все',
                        key: 'setReadAll',
                        showLabel: true,
                        disabled: loading,
                        onClick: () => {
                            modal.confirm({
                                title: 'Пометить все сообщения как прочитанные?',
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onSetReadAll();
                                },
                            });
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <RangePicker
                            style={{ width: 260 }}
                            allowEmpty={[true, true]}
                            key='created'
                            format='DD.MM.YYYY'
                            placeholder={['Отправлено с', 'до']}
                            cellRender={(current) => {
                                return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                            }}
                            value={[
                                filter?.createdFrom ? dayjs(filter?.createdFrom) : null,
                                filter?.createdTo ? dayjs(filter?.createdTo) : null,
                            ]}
                            onChange={(value) => {
                                if (!value) {
                                    d(
                                        setFilter(
                                            {
                                                ...filter,
                                                createdFrom: undefined,
                                                createdTo: undefined,
                                            },
                                            filterContext
                                        )
                                    );

                                    return;
                                }

                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            createdFrom: value[0]
                                                ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                            createdTo: value[1]
                                                ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                        },
                                        filterContext
                                    )
                                );
                            }}
                        />,
                        <Form.Item key='unreadOnly' label='Непрочитанные' style={{ margin: 0 }}>
                            <Switch
                                checked={filter?.unreadOnly}
                                onChange={(value) => {
                                    d(setFilter({ ...filter, unreadOnly: value }, filterContext));
                                }}
                            />
                        </Form.Item>,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IPushNotification> = [
            {
                title: '',
                align: 'center',
                width: 35,
                render: (_, record) => {
                    return (
                        record.isRead && (
                            <Tooltip title='Прочитано'>
                                <EyeFilled style={{ color: 'var(--primary-color)' }} />
                            </Tooltip>
                        )
                    );
                },
            },
            {
                title: 'Сообщение',
                width: 700,
                render: (_: any, record: IPushNotification) => {
                    return record.body;
                },
            },
            {
                title: 'Отправлено',
                width: 130,
                render: (_: any, record: IPushNotification) => {
                    return getDateTimeLocal(record.createdOn);
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={entities}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                onRow={(record: IPushNotification) => {
                    return {
                        style: { fontWeight: record.isRead ? '' : 600, cursor: 'pointer' },
                        onClick: (event) => {
                            if (!record.id) return;

                            let entity = entities.find((o) => o.id === record.id);
                            setCurrentEntity(entity);
                            setOpenDialog(true);
                        },
                    };
                }}
                pagination={false}
                virtual={true}
                scroll={{ y: height ?? 0 + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 190px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            {contextHolder}

            <Modal
                title={currentEntity?.subject || 'Уведомление'}
                destroyOnClose={true}
                width={700}
                open={openDialog}
                closable={false}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText='Закрыть'
                onCancel={() => {
                    onSetRead();
                }}
                styles={{ body: { overflowY: 'auto', maxHeight: 'calc(100vh - 400px)', marginBottom: 20 } }}
            >
                {currentEntity?.body}
            </Modal>

            {modalCntextHolder}
        </div>
    );
};

export default Notifications;
