import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { InputNumber, notification } from 'antd';

import { Table, Input, Select, Tooltip, Form, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
    LoadingOutlined,
    ReloadOutlined,
    CarOutlined,
    FilterFilled,
    EyeFilled,
    CheckOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import { exception } from '@extensions/notification';
import { getDate } from '@extensions/utils';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import { userLoaded, setFilter } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { ITruckFilter } from '@entities/truck-filter';
import { getEnumList, delayAction } from '@extensions/utils';
import { ITruck } from '@entities/truck';
import { ICity } from '@entities/city';
import { IConsignment } from '@entities/consignment';
import { ICountry } from '@entities/country';

import { TruckStatus, enumLabel } from '@enums/truck-status';
import { TruckType } from '@enums/truck-type';
import { IEnumItem } from '@enums/enum-item';

import { BoxesIcon } from '@icons/index';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const filterContext: string = 'DeliveryTrucks';

const Trucks = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const initFilter: ITruckFilter = {
        useIsDelivered: true,
        userId: userSession.userId,
        type: TruckType.Delivery,
        statuses: [TruckStatus.New, TruckStatus.OnWay],
        isArchived: false,
    };

    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const [modal, modalContextHolder] = Modal.useModal();

    const [api, notificationContextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const filter = useAppSelector<ITruckFilter>((s) => s.filters[filterContext]);

    const [trucks, setTrucks] = useState<Array<ITruck>>([]);
    const [currentTruck, setCurrentTruck] = useState<ITruck>();
    const [countries, setCountries] = useState<Array<ICountry>>([]);

    const [statuses] = useState<Array<IEnumItem>>(getEnumList(TruckStatus, enumLabel));

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [showFilter, setShowFilter] = useState<boolean>(true);

    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('trucks/delivery', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCountries(result[0][0]);
                setTrucks(result[0][1]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onConfirm = () => {
        if (!currentTruck) return;

        let boxGroupIds: Array<string> = [];
        currentTruck.consignments?.map((c) => {
            c.boxGroupIds?.map((id) => {
                if (id) boxGroupIds.push(id);
            });
        });

        serverFetch(`shipmentjournals/${currentTruck?.journalId}/confirmdelivery`, { method: 'POST', bodyData: boxGroupIds })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка подтверждения получения груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Информация о машине',
                        key: 'view',
                        disabled: !currentTruck,
                        icon: <CarOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentTruck?.id}`);
                        },
                    },
                    {
                        label: 'Подтвердить получение',
                        key: 'forceDelivered',
                        disabled: !currentTruck,
                        type: 'primary',
                        icon: <CheckOutlined />,
                        onClick: () => {
                            modal.confirm({
                                title: `Подтвердить получение груза "${currentTruck?.number}"?`,
                                okType: 'primary',
                                icon: <QuestionCircleOutlined />,
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onConfirm();
                                },
                            });
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                display={showFilter}
                items={[
                    <Input
                        key='number'
                        placeholder='Машина'
                        value={filter?.number}
                        onChange={(data) => {
                            d(setFilter({ ...filter, number: data.target.value }, filterContext));
                        }}
                    />,
                    <InputNumber
                        style={{ width: 130 }}
                        key='consigneeCode'
                        placeholder='ID клиента'
                        value={filter?.consigneeCode}
                        onChange={(value) => {
                            d(setFilter({ ...filter, consigneeCode: value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='markingCode'
                        placeholder='Маркировка'
                        value={filter?.markingCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='awbNumber'
                        placeholder='AWB'
                        value={filter?.awbNumber}
                        onChange={(data) => {
                            d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                        }}
                    />,
                    <Select
                        key='status'
                        placeholder='Статус машины'
                        value={filter?.statuses}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        allowClear
                        style={{ width: 200 }}
                        onChange={(value) => d(setFilter({ ...filter, statuses: value }, filterContext))}
                        options={statuses.map((s) => {
                            return { value: s.value, label: s.label };
                        })}
                    />,
                ]}
                onClear={() => d(setFilter(initFilter, filterContext))}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = trucks.find((o: ITruck) => o.journalId === selectedRowKeys[0]);
            setCurrentTruck(entity);
        } else {
            setCurrentTruck(undefined);
        }
    };

    const expandedConsignments = (record: ICity) => {
        let consignments = record.consignments?.map((p) => {
            let boxGroups: any = [];
            let totalQty: number = 0;

            p.boxGroups?.map((b) => {
                if (!b.countryId) return;

                boxGroups[b.countryId] = b.qty;
                totalQty += b.qty || 0;
            });

            p = {
                ...p,
                ...boxGroups,
                totalQty: totalQty,
            };
            return p;
        });

        const columns: ColumnsType<IConsignment> = [
            {
                title: 'ID',
                dataIndex: 'consigneeCode',
                width: 80,
                align: 'center',
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 120,
                dataIndex: 'totalQty',
                onCell: (record) => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
            },
        ];

        countries.map((c) => {
            columns.push({
                title: c.name,
                width: 230,
                render: (_: any, record: IConsignment) => {
                    let boxGroup = record.boxGroups?.find((bg) => bg.countryId === c.id);

                    return (
                        <Form colon={false} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ margin: '-12px 0 0' }}>
                            {boxGroup?.qty && (
                                <Form.Item
                                    label={
                                        <Tooltip title='Количество'>
                                            <BoxesIcon />
                                        </Tooltip>
                                    }
                                    className='country-form-item'
                                    style={{ fontWeight: 600 }}
                                >
                                    {boxGroup.qty}
                                </Form.Item>
                            )}
                            {boxGroup?.awbNumber && (
                                <Form.Item label='AWB' className='country-form-item'>
                                    <span style={{ backgroundColor: '#efefef', padding: '0 4px' }}>{boxGroup.awbNumber}</span>
                                </Form.Item>
                            )}
                        </Form>
                    );
                },
            });
        });

        return <Table rowKey='id' size='small' columns={columns} dataSource={consignments} pagination={false} />;
    };

    const expandedCities = (record: ITruck) => {
        let cities: Array<ICity> = [];
        let cityNames = [];

        record.consignments?.map((p) => {
            let city = cities.find((c) => c.name === p.cityName);
            if (!city) {
                city = {
                    name: p.cityName,
                    totalQty: 0,
                    consignments: [],
                };

                cities.push(city);
                cityNames.push(p.cityName);
            }

            p.boxGroups?.map((b) => {
                if (city) {
                    if (!city.totalQty) city.totalQty = 0;

                    city.totalQty += b.qty || 0;
                }
            });

            city.consignments?.push(p);
        });

        const columns: ColumnsType<ICity> = [
            {
                title: 'Город',
                dataIndex: 'name',
                width: 243,
                onCell: () => ({
                    style: {
                        fontWeight: 700,
                    },
                }),
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 120,
                dataIndex: 'totalQty',
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
            },
            { title: ' ' },
        ];

        return (
            <Table
                rowKey='name'
                size='small'
                columns={columns}
                dataSource={cities}
                pagination={false}
                expandable={{ expandedRowRender: expandedConsignments /* , defaultExpandedRowKeys: cityNames  */ }}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<ITruck> = [
            {
                title: 'Машина',
                dataIndex: 'number',
                width: 100,
            },
            {
                title: 'Статус',
                width: 100,
                render: (_, record) => {
                    return (
                        <>
                            {record.status ? enumLabel(record.status) : 'Не назначена'}
                            {record.trackingUrl && (
                                <Tooltip placement='topRight' title='Показать на карте'>
                                    <a className='trackingUrl' href={record.trackingUrl} target='_blank' rel='noreferrer'>
                                        <EyeFilled />
                                    </a>
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Дата отправки',
                width: 130,
                render: (_, record) => {
                    return getDate(record.departureOn);
                },
            },
            {
                title: 'Общее кол-во / шт',
                align: 'center',
                width: 160,
                dataIndex: 'totalQty',
                onCell: (record) => ({
                    style: {
                        background: '#ffee96',
                        fontWeight: 600,
                    },
                }),
            },
            {
                title: 'Телефон водителя',
                dataIndex: 'driverPhone',
                width: 150,
            },

            {
                title: 'Маршрут',
                key: 'route',
                render: (_, record) => {
                    return <>{record.citiesRoute?.join(' - ')} </>;
                },
            },
        ];

        return (
            <Table
                rowKey='journalId'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                dataSource={trucks}
                pagination={false}
                scroll={{ y: `calc(100vh - ${showFilter ? 240 : 182}px)`, x: 'max-content' }}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                }}
                onRow={(record: ITruck) => {
                    return {
                        onClick: (event) => {
                            if (!record.id) return;

                            onSelectChange([record.id]);
                        },
                    };
                }}
                expandable={{ expandedRowRender: expandedCities }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            {modalContextHolder}
            {notificationContextHolder}
        </>
    );
};

export default Trucks;
